import { ReactNode, useState } from 'react';
import { IconInterrogation, IconInterrogationFill } from '../icons';
import Message from '@components/molecules/message/message';

export type TooltipTextAlign = 'right' | 'center' | 'left';
type InputTooltipStyle = 'default' | 'alt';
export type InputTooltipPlacement =
  | 'responsive'
  | 'gutterOnly'
  | 'topRightOnly';

export interface InputTooltipProps {
  style: InputTooltipStyle;
  placement?: InputTooltipPlacement;
  children: ReactNode;
  label?: string;
  textAlign?: TooltipTextAlign;
}

const InputTooltip = ({
  style = 'default',
  placement = 'responsive',
  children,
  label,
  textAlign = 'left',
  ...props
}: InputTooltipProps) => {
  const [isActive, setIsActive] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const placementClasses =
    placement === 'responsive'
      ? 'top-0 right-0 md:top-8 md:right-[-35px]'
      : placement === 'gutterOnly'
      ? 'top-8 right-[-35px]'
      : 'top-0 right-0';
  return (
    <>
      <button
        type="button"
        className={`absolute ${placementClasses} flex gap-2 items-center`}
        onClick={(e) => setIsActive(!isActive)}
        onMouseOver={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        data-testid="input-tooltip-btn"
        {...props}
      >
        {label && (
          <span className="text-nad-alps-night hidden sm:inline">{label}</span>
        )}
        {!isHovering && !isActive && <IconInterrogation />}
        {(isHovering || isActive) && <IconInterrogationFill color="nad-blue" />}
      </button>
      {isActive && (
        <Message
          className="mt-2"
          textAlign={textAlign}
          data-testid="input-tooltip-text"
        >
          {children}
        </Message>
      )}
    </>
  );
};

export default InputTooltip;
